import { EnumApprovalStatus } from "constants/enums/approval-status"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { compose, withHooks, withStores } from "enhancers"
import gql from "graphql-tag"
import { get } from "lodash"
import { HistoryDetailPageComponent } from "pages/main/e-claim-history/detail"
import paths from "routes/paths"
import { employeeIsHr, getTaxMonth } from "utils/helper"

const API = {
  GET_HISTORY_DETAIL: gql`
    query GET_HISTORY_DETAIL($id: String!) {
      claimRequestHistoryDetailPreview(id: $id) {
        workflowSeq
        id
        type
        status
        config
        info
        employeeId
        employee
        createdAt
        updatedAt
        createdBy
        referenceId
        rejectReason
        remark
        transferDate
        canceled
        currentWorkflow
        approvedCount
        approvedAmount
        approvalSteps
      }
    }
  `,
}

const setWorkflow = (nextActionWorkflow: any, workflows: any, last: any, setWorkflowStep: any) => {
  if (nextActionWorkflow) {
    const nextActionIndex = workflows.findIndex((wf: any) => JSON.stringify(wf) === JSON.stringify(nextActionWorkflow))
    setWorkflowStep(nextActionIndex)
  } else if (last) {
    setWorkflowStep(workflows.length)
  }
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { useParams, useQuery, useState, useEffect, useMemo, useCallback } = hooks
    const { currentUserInfo } = props
    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])
    const { id } = useParams()

    const { data, refetch, loading } = useQuery(API.GET_HISTORY_DETAIL, {
      variables: { id },
      fetchPolicy: "network-only",
    })

    const [imageLoading, setImageLoading] = useState(true)

    const employee = useMemo(() => data?.claimRequestHistoryDetailPreview.employee, [data])
    const handleClose = useCallback(() => {
      paths.dashboardPath().push()
    }, [])

    const requestRejected = useMemo(
      () =>
        data?.claimRequestHistoryDetailPreview.workflowSeq.filter(
          (flow: any) => flow.status === EnumClaimRequestStatus.REJECTED,
        ).length > 0,
      [data],
    )

    const hasFile = useMemo(
      () => data?.claimRequestHistoryDetailPreview.info.documents.filter((doc: any) => doc.file) > 0,
      [data],
    )

    const currentUserIsHr = useMemo(() => employeeIsHr(currentUser.role), [currentUser])

    const isInstead = useMemo(
      () => data?.claimRequestHistoryDetailPreview.employee.id !== data?.claimRequestHistoryDetailPreview.createdBy.id,
      [data],
    )

    const requestAmount = useCallback((history: any) => {
      return history?.info?.values?.inputs[history.info.amountFieldName] || 0
    }, [])

    // const isInsurance = useMemo(() => checkInsurance(data), [data])

    const nextActionWorkflow = useMemo(() => {
      const workflows = data?.claimRequestHistoryDetailPreview.workflowSeq
      const currentWorkflow = workflows?.find(
        (flow: any) => flow.seq === data?.claimRequestHistoryDetailPreview.currentWorkflow + 1,
      )
      return currentWorkflow
    }, [data])

    const [workflowStep, setWorkflowStep] = useState(0)

    const mappingPaymentTypeMessage = useMemo(() => {
      const inputValues = data?.claimRequestHistoryDetailPreview.info["values"]["inputs"]
      const paymentType = get(inputValues, "payment", "โอนเงินเข้าบัญชีเงินเดือน")
      return paymentType
    }, [data])

    useEffect(() => {
      // refetch()
      if (data?.claimRequestHistoryDetailPreview.workflowSeq) {
        const workflows = data?.claimRequestHistoryDetailPreview.workflowSeq
        const last = workflows[workflows.length - 1]
        setWorkflow(nextActionWorkflow, workflows, last, setWorkflowStep)
      }
    }, [data, nextActionWorkflow])

    const initialDocumentValues = useMemo(() => {
      let initialDocuments = {}
      data?.claimRequestHistoryDetailPreview.info.documents.forEach((doc: any) => {
        const docs = data?.claimRequestHistoryDetailPreview.info.values.documents[doc.name]
        initialDocuments = {
          ...initialDocuments,
          [doc.name]: docs || [],
        }
      })

      return initialDocuments
    }, [
      data?.claimRequestHistoryDetailPreview.info.documents,
      data?.claimRequestHistoryDetailPreview.info.values.documents,
    ])

    const [documentValues, setDocumentValues] = useState(initialDocumentValues)

    useEffect(() => {
      setDocumentValues(initialDocumentValues)
    }, [initialDocumentValues])

    const isLoaded = useMemo(() => !loading, [loading])

    const hrWorkflowIndex = useMemo(
      // only bam
      () =>
        data?.claimRequestHistoryDetailPreview?.workflowSeq?.findIndex(
          (item: any) => item.workflow.custom === "approvers10kLv6",
        ),
      [data?.claimRequestHistoryDetailPreview?.workflowSeq],
    )

    const hrWorkflow = useMemo(() => data?.claimRequestHistoryDetailPreview?.workflowSeq[hrWorkflowIndex], [
      data?.claimRequestHistoryDetailPreview?.workflowSeq,
      hrWorkflowIndex,
    ])

    const nextHrWorkflow = useMemo(() => data?.claimRequestHistoryDetailPreview?.workflowSeq[hrWorkflowIndex + 1], [
      data?.claimRequestHistoryDetailPreview?.workflowSeq,
      hrWorkflowIndex,
    ])

    const isHrApproved = useMemo(() => hrWorkflow?.status === EnumApprovalStatus.APPROVED, [hrWorkflow])
    const summary = useMemo(() => nextHrWorkflow?.workflow.summary, [nextHrWorkflow?.workflow.summary])
    const amountValue = useMemo(() => {
      const amountFieldName = data?.claimRequestHistoryDetailPreview?.info?.amountFieldName ?? null

      const inputs = amountFieldName
        ? data?.claimRequestHistoryDetailPreview?.info?.values?.inputs[amountFieldName] ?? null
        : null
      const sanitizedInputs = inputs ? String(inputs).replace(/,/g, "") : null
      return Number(sanitizedInputs)
    }, [
      data?.claimRequestHistoryDetailPreview?.info?.amountFieldName,
      data?.claimRequestHistoryDetailPreview?.info?.values?.inputs,
    ])

    const monthForTax = useMemo(() => {
      if (
        data?.claimRequestHistoryDetailPreview.transferDate &&
        data?.claimRequestHistoryDetailPreview?.config.includeTax
      )
        return getTaxMonth(data?.claimRequestHistoryDetailPreview.transferDate)
    }, [data?.claimRequestHistoryDetailPreview])

    return {
      handleClose,
      createdAt: data?.claimRequestHistoryDetailPreview.createdAt,
      updatedAt: data?.claimRequestHistoryDetailPreview.updatedAt,
      inputs: data?.claimRequestHistoryDetailPreview.info.inputs,
      documents: data?.claimRequestHistoryDetailPreview.info.documents || [],
      inputValues: data?.claimRequestHistoryDetailPreview.info.values.inputs,
      documentValues,
      claimId: data?.claimRequestHistoryDetailPreview.referenceId,
      createdBy:
        data?.claimRequestHistoryDetailPreview.createdBy.firstName +
        " " +
        data?.claimRequestHistoryDetailPreview.createdBy.lastName,
      requesterValues: employee ? employee.firstName + " " + employee.lastName : undefined,
      requester: employee,

      workflows: data?.claimRequestHistoryDetailPreview.workflowSeq || [],
      workflowStep: requestRejected ? Math.max(workflowStep - 1, 0) : workflowStep,
      hasFile,
      title: data?.claimRequestHistoryDetailPreview.info.title || "",
      subTitle: data?.claimRequestHistoryDetailPreview.info.subTitle,
      icon: data?.claimRequestHistoryDetailPreview.info.icon,
      iconColor: data?.claimRequestHistoryDetailPreview.info.iconColor,
      chipTitleColor: data?.claimRequestHistoryDetailPreview.info.chipTitleColor,
      chipBackgroundColor: data?.claimRequestHistoryDetailPreview.info.chipBackgroundColor,
      type: data?.claimRequestHistoryDetailPreview.info.type,
      name: data?.claimRequestHistoryDetailPreview.info.name,
      approvedAmount: data?.claimRequestHistoryDetailPreview?.approvedAmount,
      approvedCount: data?.claimRequestHistoryDetailPreview?.approvedCount,
      claimStatus: data?.claimRequestHistoryDetailPreview.status,
      transferDate: data?.claimRequestHistoryDetailPreview.transferDate || null,
      rejectReason: data?.claimRequestHistoryDetailPreview.rejectReason || null,
      remark: data?.claimRequestHistoryDetailPreview.remark || null,
      currentUserIsHr,
      isInstead,
      isInsurance: false,
      canceled: data?.claimRequestHistoryDetailPreview.canceled,
      checkApprovalStatus: data?.claimRequestHistoryDetailPreview.workflowSeq.some((e: any) => e.status === "APPROVED"),

      claimFor: data?.claimRequestHistoryDetailPreview.info.claimFor,
      affiliation: data?.claimRequestHistoryDetailPreview.employee.department || "-",
      email: data?.claimRequestHistoryDetailPreview.employee.officialMailId || "-",
      employeeGroup: data?.claimRequestHistoryDetailPreview.employee.title || "-",
      phoneNumber: data?.claimRequestHistoryDetailPreview.employee.phoneNumber || "-",
      claimRequest: data?.claimRequestHistoryDetailPreview,
      requestAmount,

      mappingPaymentTypeMessage,
      isLoaded,

      imageLoading,
      setImageLoading,

      isHrApproved,
      summary,
      transferFast: data?.claimRequestHistoryDetailPreview.info.transferFast,
      amount: amountValue,
      currentPoint: data?.claimRequestHistoryDetailPreview?.employee?.currentPoint || 0,

      monthForTax,
      includeTax: data?.claimRequestHistoryDetailPreview.info.includeTax,
      withoutCancel: true,
    }
  }),
)

export default enhancer(HistoryDetailPageComponent)
