import { compose, withHooks, withStores } from "enhancers"
import { Box, Button, Divider, Grid, IconButton, Modal, Notification, Typography } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { StepStatus } from "components/advance/StepStatus"
import { UploadDocumentCard } from "components/advance/UploadDocumentCard"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import dayjs from "dayjs"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { employeeIsHr, getTaxMonth, gql, isJson, paths, toCurrency } from "utils/helper"
import { Field } from "../../claim-detail/form-field"
import { DefaultPreviewComponent } from "pages/main/claim-detail/form-field/preview/Default"
import LoadingModal from "components/LoadingModal"
import { INPUT_TYPE } from "constants/enums/input-type"
import { SummaryComponent } from "pages/main/claim-detail/SummaryComponent"
import { EnumApprovalStatus } from "constants/enums/approval-status"
import { get } from "utils/lodash"
import { Icon } from "components/common"
import { ReactComponent as Point } from "assets/icon/point.svg"
import { Card, CardContent } from "@material-ui/core"
import { env } from "configs"
import { ClaimIdDisplay } from "components/advance/ClaimIdDisplay"

const DetailStatusContainer = styled("div")`
  position: relative;
  background-color: ${AppColor["Primary/Line"]};
  height: auto;
  padding: 16px;
`

const ClaimDetail = styled("div")`
  position: relative;
  background: ${AppColor["White / White"]};
  height: auto;
  padding: 16px;
`

const CloseButton = styled(IconButton)`
  color: ${AppColor["White / White"]};
  padding: 0px;
`

const Title = styled("div")`
  margin-top: 24px;
  display: flex;
  align-items: center;
`

const TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DetailBox = styled(Box)`
  margin-top: 16px;
`

const FlexBox = styled(Box)<{ mb?: string }>`
  display: flex;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const FrontBox = styled(Box)<{ mr?: string }>`
  min-width: 100px;
  margin-right: ${(props) => (props.mr ? props.mr : "0px")};
`

const LightBlueBox = styled(Box)`
  background: ${AppColor["White / White"]};
  border-radius: 8px;
  margin-top: 16px;
`

const WarningIcon = styled(Icon)`
  font-size: 16px;
  margin-right: 4px;
`

const WarningBox = styled(Box)<{ mt?: string; mb?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  background-color: ${AppColor["System/Warning Light"]};
  border-radius: 8px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

export const HistoryDetailPageComponent = (props: any) => (
  <>
    <LoadingModal isOpen={!props.isLoaded || props.imageLoading} />
    {props.isLoaded && (
      <Box style={{ backgroundColor: AppColor["White / White"] }}>
        <Box style={{ backgroundColor: AppColor["Primary/Line"] }}>
          <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
            <DetailStatusContainer>
              <TopBox>
                <Typography variant="h2" color="White / White">
                  รายละเอียดการเบิก
                </Typography>
                <CloseButton onClick={props.handleClose}>
                  <Icon name="Close" style={{ fontSize: "24px" }} />
                </CloseButton>
              </TopBox>
              <DetailBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      ผู้เบิก
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.createdBy}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      ฝ่าย/สังกัด
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.affiliation}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      กลุ่มพนักงาน
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.employeeGroup}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      เบอร์โทร
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.phoneNumber}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      อีเมล
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.email}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      เลขอ้างอิง
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    <ClaimIdDisplay claimId={props.claimId} />
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      วันที่เบิก
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {dayjs(props.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px" minWidth={325} maxWidth={730} overflow="hidden">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      สถานะ
                    </Typography>
                  </FrontBox>
                  <StepStatus
                    claimStatus={props.claimStatus}
                    workflows={props.workflows}
                    workflowStep={props.workflowStep}
                    canceled={props.canceled}
                  />
                </FlexBox>
                <FlexBox>
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      แก้ไขล่าสุด
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {dayjs(props.updatedAt).format("DD/MM/YYYY")}
                  </Typography>
                </FlexBox>
              </DetailBox>
              {props.claimStatus === EnumClaimRequestStatus.WAITING &&
                !props.withoutCancel &&
                !props.checkApprovalStatus && (
                  <>
                    <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
                    <Button
                      onClick={props.onClickCancelClaim}
                      width="100%"
                      color="primary"
                      style={{ border: "none", background: "none", textDecoration: "underline" }}
                    >
                      <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                        <Icon name="cancel" />
                        <Typography variant="body1" color="White / White">
                          ยกเลิกการเบิก
                        </Typography>
                      </Box>
                    </Button>
                  </>
                )}
              {props.claimStatus === EnumClaimRequestStatus.APPROVED && props.transferDate && (
                <LightBlueBox padding="8px 24px">
                  <Box display="flex" flexDirection="column" textAlign="center">
                    <Typography variant="body1" color="Text/Primary Text">
                      {`${props.mappingPaymentTypeMessage} ${toCurrency(props.amount, {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })} บาท`}
                    </Typography>
                    {props.transferDate && (
                      <Typography variant="body1" color="Text/Primary Text">
                        {`ให้พนักงานวันที่ ${dayjs(props.transferDate).format("DD/MM/YYYY")}`}
                      </Typography>
                    )}
                    {props.includeTax && (
                      <Typography variant="body1" color="Text/Placeholder">
                        หักภาษีเดือน {props.monthForTax}
                      </Typography>
                    )}
                    {props.transferFast && (
                      <Typography variant="body1" color="Text/Placeholder">
                        [จ่ายเป็นกรณีเร่งด่วน]
                      </Typography>
                    )}
                  </Box>
                </LightBlueBox>
              )}
              {props.claimStatus === EnumClaimRequestStatus.REJECTED && (
                <LightBlueBox padding="8px 16px">
                  <FlexBox>
                    <FrontBox mr="8px">
                      <Typography variant="body1" color={AppColor["Primary/Line"]}>
                        เหตุผล:
                      </Typography>
                    </FrontBox>
                    <Typography variant="body1" color={AppColor["Primary/Line"]}>
                      {props.rejectReason || "-"}
                    </Typography>
                  </FlexBox>
                  <FlexBox mt="8px">
                    <FrontBox mr="8px">
                      <Typography variant="body1" color={AppColor["Primary/Line"]}>
                        หมายเหตุ:
                      </Typography>
                    </FrontBox>
                    <Typography variant="body1" color={AppColor["Primary/Line"]}>
                      {props.remark || "-"}
                    </Typography>
                  </FlexBox>
                </LightBlueBox>
              )}
            </DetailStatusContainer>
          </Box>
        </Box>
        <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
          <ClaimDetail>
            {props.currentUserIsHr && props.isInstead && !props.withoutCancel && (
              <>
                <WarningBox mt="8px" mb="24px">
                  <WarningIcon name="Warning" color={AppColor["Warning"]}></WarningIcon>
                  <Typography variant="body2" color={AppColor["Text/Primary Text"]}>
                    กำลังทำรายการแทนพนักงานคนอื่น
                  </Typography>
                </WarningBox>
              </>
            )}
            <Box mt="8px">
              <ClaimCard
                title={props.title}
                type={props.type}
                name={props.name}
                approvedRequest={props.approvedCount}
                totalAmount={props.approvedAmount}
                icon={props.icon}
                iconColor={props.iconColor}
                chipTitleColor={props.chipTitleColor}
                chipBackgroundColor={props.chipBackgroundColor}
                fullSize
                displayOnly
              />
            </Box>
            {props.summary && props.isHrApproved && (
              <SummaryComponent
                summary={props.summary}
                total={props.approvedAmount}
                inputs={props.inputs}
                values={props.inputValues}
              />
            )}
            <Title>
              <Icon
                name="BorderColor"
                style={{ marginRight: "8px" }}
                width="24px"
                height="24px"
                color={AppColor["Primary/Line"]}
              />
              <Typography variant="h3">รายละเอียดการเบิก</Typography>
            </Title>

            <Grid container style={{ paddingTop: 16 }}>
              <Grid item xs={12} key="select-employee">
                <Typography variant="body1" color="Text/Gray Preview">
                  ผู้ขอเบิก
                </Typography>
                <Field isPreview={true} component="SELECT" label="" name="requester" value={props.requesterValues} />
              </Grid>
              {props.claimFor && props.claimFor !== "Self" && (
                <Grid item xs={12} key="select-relations" style={{ paddingTop: 16 }}>
                  <DefaultPreviewComponent component="INPUT" label="เบิกให้" value={props.claimFor.nameTh} />
                </Grid>
              )}
              {props.inputs?.map((input: any) => (
                <Grid
                  item
                  xs={12}
                  key={`${input.name}-4`}
                  style={{ paddingTop: input.type === INPUT_TYPE.SUM_AMOUNT ? 0 : 16 }}
                >
                  <Field
                    isPreview={true}
                    value={props.inputValues[input.name]}
                    component={input.type}
                    label={input.title}
                    name={input.name}
                    options={input.options}
                    unit={input.unit}
                    requester={props.requester}
                  />
                </Grid>
              ))}
            </Grid>
            {props.documents.length > 0 && (
              <UploadDocumentCard
                documents={props.documents}
                values={props.documentValues}
                onChange={props.handleFilesChange}
                canDelete={false}
                isPreview={true}
                onLoading={(loading) => props.setImageLoading(loading)}
              />
            )}
            {env.COIN_SOURCE === "coin_module" && (
              <div>
                <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "16px" }}>
                  <Point></Point>
                  <Typography variant="h3" color="black">
                    สรุปการใช้คะแนน
                  </Typography>
                </div>
                <Card style={{ border: "1px solid #d4d4d4", borderRadius: "8px" }}>
                  <CardContent style={{ padding: "16px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
                      <Typography variant="h4" color={AppColor["Text/Primary Text"]}>
                        คะแนนที่ใช้
                      </Typography>
                      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <Typography variant="Body/16" color={AppColor["Primary/Primary Text"]} isCurrency>
                          {props.amount}
                        </Typography>
                        <Typography variant="Body/16" color={AppColor["Primary/Primary Text"]}>
                          คะแนน
                        </Typography>
                      </div>
                    </div>
                    <div style={{ marginTop: "8px" }} />
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Typography variant="h4" color="Text/Line" isCurrency>
                        คงเหลือ {props.currentPoint} คะแนน
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )}
          </ClaimDetail>
        </Box>
      </Box>
    )}
  </>
)

const API = {
  GET_HISTORY_DETAIL: gql`
    query GET_HISTORY_DETAIL($id: String!) {
      claimRequestHistoryDetail(id: $id) {
        workflowSeq
        id
        type
        status
        config
        info
        employeeId
        employee
        createdAt
        updatedAt
        createdBy
        referenceId
        rejectReason
        remark
        transferDate
        canceled
        currentWorkflow
        approvedCount
        approvedAmount
        approvalSteps
      }
    }
  `,
  CANCEL_REQUEST: gql`
    mutation CANCEL_REQUEST($id: String!) {
      cancelClaimRequest(id: $id) {
        id
        status
      }
    }
  `,
}

const navigateOnClose = (withoutCancel: any, tab: any, filters: any) => {
  if (withoutCancel) {
    paths.dashboardPath().push()
  } else {
    paths.historyPath({ tab: tab, filters: filters }).push()
  }
}

const showErrorModal = (e: any, handleCloseErrorModal: any) => {
  if (e) {
    // @ts-ignore
    Modal.alert({
      className: "deleteConFirmModal",
      title: "",
      children: (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box mb="16px">
            <Icon fontSize="large" color={AppColor["Warning"]} name="Warning"></Icon>
          </Box>
          <Box mb="16px">
            <Typography variant="body1" color="Text/Black2">
              {e.message}
            </Typography>
          </Box>
        </Box>
      ),
      okButtonLabel: "ตกลง",
      okButtonVariant: "contained",
      buttonWidth: "100%",
      onOk: handleCloseErrorModal,
    })
  }
}

const setWorkflow = (nextActionWorkflow: any, workflows: any, last: any, setWorkflowStep: any) => {
  if (nextActionWorkflow) {
    const nextActionIndex = workflows.findIndex((wf: any) => JSON.stringify(wf) === JSON.stringify(nextActionWorkflow))
    setWorkflowStep(nextActionIndex)
  } else if (last) {
    setWorkflowStep(workflows.length)
  }
}

const checkInsurance = (data: any) => {
  return (
    data?.claimRequestHistoryDetail.info.type === "ประกันกลุ่ม" ||
    data?.claimRequestHistoryDetail.info.type === "ประกันสังคม"
  )
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { useParams, useQuery, useState, useEffect, useMutation, useMemo, useCallback, useUrlParam } = hooks
    const { withoutCancel, currentUserInfo } = props
    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])
    const { id } = useParams()

    const queryParams = useUrlParam()
    const { tab, filters } = queryParams

    const { data, refetch, loading } = useQuery(API.GET_HISTORY_DETAIL, {
      variables: { id },
      onError: (error: any) => {
        if (isJson(error.message)) {
          const message = JSON.parse(error.message)[0]
          console.log(message)
          if (message) {
            // @ts-ignore
            Modal.alert({
              className: "deleteConFirmModal",
              title: "",
              children: (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Icon width="40px" height="40px" name="Cancel" color={AppColor["Other/Danger"]} />
                  <Box mb="16px" mt="16px">
                    <Typography variant="Body/14" color="Text/Black2">
                      {message}
                    </Typography>
                  </Box>
                </Box>
              ),
              okButtonLabel: "ยืนยัน",
              okButtonVariant: "contained",
              // cancelButtonLabel: "ปิดหน้าต่างนี้",
              // cancelButtonVariant: "outlined",
              // buttonWidth: 147,
              onOk: ({ close }: any) => {
                close()
                paths.historyPath({ tab: tab }).push()
              },
            })
          }
        }
      },
      fetchPolicy: "network-only",
    })
    const [cancelRequest] = useMutation(API.CANCEL_REQUEST)

    const [imageLoading, setImageLoading] = useState(true)

    const employee = useMemo(() => data?.claimRequestHistoryDetail.employee, [data])
    const handleClose = useCallback(() => {
      navigateOnClose(withoutCancel, tab, filters)
    }, [withoutCancel, tab, filters])

    const requestRejected = useMemo(
      () =>
        data?.claimRequestHistoryDetail.workflowSeq.filter(
          (flow: any) => flow.status === EnumClaimRequestStatus.REJECTED,
        ).length > 0,
      [data],
    )

    const hasFile = useMemo(() => data?.claimRequestHistoryDetail.info.documents.filter((doc: any) => doc.file) > 0, [
      data,
    ])

    const currentUserIsHr = useMemo(() => employeeIsHr(currentUser.role), [currentUser])

    const isInstead = useMemo(
      () => data?.claimRequestHistoryDetail.employee.id !== data?.claimRequestHistoryDetail.createdBy.id,
      [data],
    )

    const requestAmount = useCallback((history: any) => {
      return history?.info?.values?.inputs[history.info.amountFieldName] || 0
    }, [])

    const handleCloseErrorModal = useCallback(
      (confirmProps: any) => {
        confirmProps.close()
        paths.historyPath({ tab: tab, filters: filters }).push()
      },
      [tab, filters],
    )

    const handleCancelClaim = useCallback(
      async (confirmProps: any) => {
        try {
          confirmProps.close()
          await cancelRequest({ variables: { id } })

          // @ts-ignore
          Notification.success("ยกเลิกคำร้องสำเร็จ")
          paths.historyPath({ tab: tab, filters: filters }).push()
        } catch (e: any) {
          showErrorModal(e, handleCloseErrorModal)
        }
      },
      [cancelRequest, id, handleCloseErrorModal, tab, filters],
    )

    const onClickCancelClaim = useCallback(() => {
      // @ts-ignore
      Modal.confirm({
        className: "deleteConFirmModal",
        title: "",
        children: (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Icon width="40px" height="40px" name="Cancel" color={AppColor["Other/Danger"]} />
            <Box mb="16px" mt="16px">
              <Typography variant="h3" color="Text/Black2">
                ต้องการยกเลิกการเบิกสวัสดิการ ?
              </Typography>
            </Box>
            <Box mb="4px">
              <Typography variant="body1" color="Text/Gray Preview">
                ยกเลิกการเบิก
              </Typography>
            </Box>
            <Box mb="16px">
              <Typography variant="h6" color={AppColor["Primary/Primary Text"]}>
                {data?.claimRequestHistoryDetail.info.title +
                  " " +
                  requestAmount(data?.claimRequestHistoryDetail) +
                  " บาท"}
              </Typography>
            </Box>
          </Box>
        ),
        okButtonLabel: (
          <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <Icon name="Check_circle" />
            <Typography variant="body1">ยืนยัน</Typography>
          </Box>
        ),
        okButtonVariant: "contained",
        cancelButtonLabel: (
          <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <Icon name="cancel" color={`${AppColor["Primary/Primary Text"]}`} />
            <Typography variant="body1">ยกเลิก</Typography>
          </Box>
        ),
        cancelButtonVariant: "outlined",
        buttonWidth: 147,
        onOk: handleCancelClaim,
      })
    }, [handleCancelClaim, data, requestAmount])

    const isInsurance = useMemo(() => checkInsurance(data), [data])

    const nextActionWorkflow = useMemo(() => {
      const workflows = data?.claimRequestHistoryDetail.workflowSeq
      const currentWorkflow = workflows?.find(
        (flow: any) => flow.seq === data?.claimRequestHistoryDetail.currentWorkflow + 1,
      )
      return currentWorkflow
    }, [data])

    const [workflowStep, setWorkflowStep] = useState(0)

    const mappingPaymentTypeMessage = useMemo(() => {
      const inputValues = data?.claimRequestHistoryDetail.info["values"]["inputs"]
      console.log("inputValues", inputValues)
      const paymentType = get(inputValues, "payment", "โอนเงินเข้าบัญชีเงินเดือน")
      return paymentType
    }, [data])

    useEffect(() => {
      // refetch()
      if (data?.claimRequestHistoryDetail.workflowSeq) {
        const workflows = data?.claimRequestHistoryDetail.workflowSeq
        const last = workflows[workflows.length - 1]
        setWorkflow(nextActionWorkflow, workflows, last, setWorkflowStep)
      }
    }, [data, nextActionWorkflow])

    const initialDocumentValues = useMemo(() => {
      let initialDocuments = {}
      data?.claimRequestHistoryDetail.info.documents.forEach((doc: any) => {
        const docs = data?.claimRequestHistoryDetail.info.values.documents[doc.name]
        initialDocuments = {
          ...initialDocuments,
          [doc.name]: docs || [],
        }
      })

      return initialDocuments
    }, [data?.claimRequestHistoryDetail.info.documents, data?.claimRequestHistoryDetail.info.values.documents])

    const [documentValues, setDocumentValues] = useState(initialDocumentValues)

    useEffect(() => {
      setDocumentValues(initialDocumentValues)
    }, [initialDocumentValues])

    const isLoaded = useMemo(() => !loading, [loading])

    const hrWorkflowIndex = useMemo(
      // only bam
      () =>
        data?.claimRequestHistoryDetail?.workflowSeq?.findIndex(
          (item: any) => item.workflow.custom === "approvers10kLv6",
        ),
      [data?.claimRequestHistoryDetail?.workflowSeq],
    )

    const hrWorkflow = useMemo(() => data?.claimRequestHistoryDetail?.workflowSeq[hrWorkflowIndex], [
      data?.claimRequestHistoryDetail?.workflowSeq,
      hrWorkflowIndex,
    ])

    const nextHrWorkflow = useMemo(() => data?.claimRequestHistoryDetail?.workflowSeq[hrWorkflowIndex + 1], [
      data?.claimRequestHistoryDetail?.workflowSeq,
      hrWorkflowIndex,
    ])

    const isHrApproved = useMemo(() => hrWorkflow?.status === EnumApprovalStatus.APPROVED, [hrWorkflow])
    const summary = useMemo(() => nextHrWorkflow?.workflow.summary, [nextHrWorkflow?.workflow.summary])
    const amountValue = useMemo(() => {
      const amountFieldName = data?.claimRequestHistoryDetail?.info?.amountFieldName ?? null

      const inputs = amountFieldName
        ? data?.claimRequestHistoryDetail?.info?.values?.inputs[amountFieldName] ?? null
        : null
      const sanitizedInputs = inputs ? String(inputs).replace(/,/g, "") : null
      return Number(sanitizedInputs)
    }, [data?.claimRequestHistoryDetail?.info?.amountFieldName, data?.claimRequestHistoryDetail?.info?.values?.inputs])

    const monthForTax = useMemo(() => {
      // if (data?.claimRequestHistoryDetail.transferDate && data?.claimRequestHistoryDetail?.config.includeTax) {
      //   const date = dayjs(data.claimRequestHistoryDetail.transferDate)
      //   let month = date.month()
      //   let year = date.year()
      //   if (date.day() > 16) {
      //     month = (month + 1) % 12
      //     if (month === 0) {
      //       year++
      //     }
      //   }
      //   const taxMonth = new Date(year, month + 1, 1).toLocaleDateString("th-TH", { month: "long", year: "numeric" })
      //   return taxMonth
      // }
      if (data?.claimRequestHistoryDetail.transferDate && data?.claimRequestHistoryDetail?.config.includeTax)
        return getTaxMonth(data?.claimRequestHistoryDetail.transferDate)
    }, [data?.claimRequestHistoryDetail])

    return {
      handleClose,
      createdAt: data?.claimRequestHistoryDetail.createdAt,
      updatedAt: data?.claimRequestHistoryDetail.updatedAt,
      inputs: data?.claimRequestHistoryDetail.info.inputs,
      documents: data?.claimRequestHistoryDetail.info.documents || [],
      inputValues: data?.claimRequestHistoryDetail.info.values.inputs,
      documentValues,
      claimId: data?.claimRequestHistoryDetail.referenceId,
      createdBy:
        data?.claimRequestHistoryDetail.createdBy.firstName + " " + data?.claimRequestHistoryDetail.createdBy.lastName,
      requesterValues: employee ? employee.firstName + " " + employee.lastName : undefined,
      requester: employee,

      workflows: data?.claimRequestHistoryDetail.workflowSeq || [],
      workflowStep: requestRejected ? Math.max(workflowStep - 1, 0) : workflowStep,
      hasFile,
      title: data?.claimRequestHistoryDetail.info.title || "",
      subTitle: data?.claimRequestHistoryDetail.info.subTitle,
      icon: data?.claimRequestHistoryDetail.info.icon,
      iconColor: data?.claimRequestHistoryDetail.info.iconColor,
      chipTitleColor: data?.claimRequestHistoryDetail.info.chipTitleColor,
      chipBackgroundColor: data?.claimRequestHistoryDetail.info.chipBackgroundColor,
      type: data?.claimRequestHistoryDetail.info.type,
      name: data?.claimRequestHistoryDetail.info.name,
      approvedAmount: data?.claimRequestHistoryDetail?.approvedAmount,
      approvedCount: data?.claimRequestHistoryDetail?.approvedCount,
      claimStatus: data?.claimRequestHistoryDetail.status,
      transferDate: data?.claimRequestHistoryDetail.transferDate || null,
      rejectReason: data?.claimRequestHistoryDetail.rejectReason || null,
      remark: data?.claimRequestHistoryDetail.remark || null,
      onClickCancelClaim,
      currentUserIsHr,
      isInstead,
      isInsurance,
      canceled: data?.claimRequestHistoryDetail.canceled,
      withoutCancel,
      checkApprovalStatus: data?.claimRequestHistoryDetail.workflowSeq.some((e: any) => e.status === "APPROVED"),

      claimFor: data?.claimRequestHistoryDetail.info.claimFor,
      affiliation: data?.claimRequestHistoryDetail.employee.department || "-",
      email: data?.claimRequestHistoryDetail.employee.officialMailId || "-",
      employeeGroup: data?.claimRequestHistoryDetail.employee.title || "-",
      phoneNumber: data?.claimRequestHistoryDetail.employee.phoneNumber || "-",
      claimRequest: data?.claimRequestHistoryDetail,
      requestAmount,

      mappingPaymentTypeMessage,
      isLoaded,

      imageLoading,
      setImageLoading,

      isHrApproved,
      summary,
      transferFast: data?.claimRequestHistoryDetail.info.transferFast,
      amount: amountValue,
      currentPoint: data?.claimRequestHistoryDetail?.employee?.currentPoint || 0,

      monthForTax,
      includeTax: data?.claimRequestHistoryDetail.info.includeTax,
    }
  }),
)

export default enhancer(HistoryDetailPageComponent)
